/*
 * Copyright 2019 LABOR.digital
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Last modified: 2017.12.19 at 18:21
 */

import {G8Framework} from "@labor/blocks.js/lib/Legacy.js";

G8Framework.initializeBlock("data-block-section-toggle", function ($this) {
	"use strict";

	var $obj = {
		"toggler": "data-bst-toggle",
		"sections": "data-bst-section"
	};
	$this.obj($obj);

	var config = {
		"startOpen": $this.dataFallback("bst-open", false)
	};

	var methods = {
		"eventhandler": {
			"onToggleClick": function () {
				var $o = $(this);
				var s = $o.data("bst-toggle");
				var $s = $obj.sections.filter("[data-bst-section=" + s + "]");
				if (!$s.dataFallback("isExpanded", false)) {
					$s.slideDown().data("isExpanded", true).addClass("expanded");
					$o.addClass("expanded");
				} else {
					$s.slideUp().data("isExpanded", false).removeClass("expanded");
					$o.removeClass("expanded");
				}
			}
		}
	};

	// Register eventhandler
	$obj.toggler.on("click", methods.eventhandler.onToggleClick);

	// Initialize
	if (config.startOpen) {
		$obj.sections.addClass("on expanded").removeClass("off").data("isExpanded", true);
		$obj.toggler.addClass("expanded");
	} else {
		$obj.sections.addClass("off").removeClass("on expanded");
		$obj.toggler.removeClass("expanded");
	}

});
