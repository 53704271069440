/*
 * Copyright 2019 LABOR.digital
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Last modified: 2017.12.19 at 11:34
 */

import {G8Framework} from "@labor/blocks.js/lib/Legacy";
import {moneyAsNumber} from "@labor/helferlein/lib/FormatAndConvert/moneyAsNumber";
import {numberAsMoney} from "@labor/helferlein/lib/FormatAndConvert/numberAsMoney";

G8Framework.initializeBlock("data-block-money-field", function ($this) {
	"use strict";
	
	var methods = {
		"eventhandler": {
			"onFocus": function (e) {
				var $o = $(this);
				if (moneyAsNumber($o.val()) < 1) {
					$o.val("");
				}
			},
			
			"onFocusLoss": function (e) {
				var $o = $(this);
				$o.val(numberAsMoney(moneyAsNumber($o.val())));
			}
			
		}
	};
	
	// Register eventhandler
	$this
		.on("focus", methods.eventhandler.onFocus)
		.on("focusout", methods.eventhandler.onFocusLoss)
		.on("val", methods.eventhandler.onFocusLoss);
	
	// Initialize
	$this.trigger("focusout");
});