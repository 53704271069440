/*
 * Copyright 2019 LABOR.digital
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Last modified: 2017.12.20 at 14:51
 */

import {G8Framework} from "@labor/blocks.js/lib/Legacy";
import {PlainObject} from "@labor/helferlein/lib/Interfaces/PlainObject";

declare global {
	interface Window {
		webhub: {
			on: Function,
			trigger: Function
		}
	}
}

G8Framework.initializeBlock("data-block-lead-snippet-selector", function ($this) {
	"use strict";
	
	var $obj: PlainObject = {
		"canvas": "data-blss-canvas",
		"typeSelect": "data-blss-type",
		"campaignSelect": "data-blss-campaign"
	};
	$this.obj($obj);
	
	var config = {
		"key": $this.dataFallback("blss-key", "snippetSelect")
	};
	
	var vars = {
		"type": null,
		"typeLabel": null,
		"campaign": null
	};
	
	var methods = {
		"eventhandler": {
			"onTypeChange": function () {
				var val = $obj.typeSelect.val();
				if (val === vars.type) return;
				vars.type = val;
				vars.typeLabel = $obj.typeSelect.find("option:selected").text();
				methods.updateCanvas();
			},
			
			"onCampaignChange": function () {
				var val = $obj.campaignSelect.val();
				if (val === vars.campaign) return;
				vars.campaign = val;
				methods.updateCanvas();
			}
		},
		
		"updateCanvas": function () {
			$obj.canvas.html(methods.buildCanvasContent());
			// Trigger domchange for webhub scripts
			window.webhub.trigger("domChange");
		},
		
		"buildCanvasContent": function () {
			if (vars.type === null || vars.type === "") return "";
			// Prepare meta
			const meta: PlainObject = {
				"snippet": vars.type,
				"headline": vars.typeLabel,
				"leadstool": true
			};
			// Add additional meta
			if (vars.campaign !== null) meta.utmCampaign = vars.campaign;
			const metaString = JSON.stringify(meta).replace(/"/g, "&quot;");
			// Build output
			return "<div data-block-hur-snippet=\"" + config.key + "\" data-bhs-meta=\"" + metaString + "\"></div>";
		}
	};
	
	// Check if bridge exists
	if (typeof window.webhub === "undefined") {
		throw new Error("The webhub snippet bridge was not found!");
	}
	
	// Register eventhandler
	$obj.typeSelect.on("change", methods.eventhandler.onTypeChange);
	$obj.campaignSelect.on("change", methods.eventhandler.onCampaignChange);
	
});