/*
 * Copyright 2019 LABOR.digital
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Last modified: 2017.12.19 at 11:31
 */

import "./Selectize.scss";
import {G8Framework} from "@labor/blocks.js/lib/Legacy";
import "selectize/dist/js/standalone/selectize.js";

G8Framework.initializeBlock("data-block-selectize", function ($this) {
	"use strict";
	
	var config = {
		"placeholder": $this.dataFallback("dbs-placeholder", "Bitte auswählen")
	};
	
	var methods = {
		"eventhandler": {
			"onValueChange": function () {
				// Trigger unified event
				$this.trigger("val");
				// Add value class
				methods.markCurrentValue();
				methods.appendClearButton();
			},
			
			"onClearClick": function (e) {
				e.preventDefault();
				e.stopPropagation();
				var s = $this[0].selectize;
				s.close();
				s.clear();
				methods.appendClearButton();
				return false;
			}
		},
		
		"delayedInit": function () {
			methods.appendClearButton();
			methods.markCurrentValue();
		},
		
		"appendClearButton": function () {
			// Add button
			$this.siblings(".selectize-control").find("> .selectize-input").each(function (k, v) {
				var $o = $(v);
				$o.append("<span class=\"selectize-control-close\"></span>");
				$o.find(".selectize-control-close").on("click", methods.eventhandler.onClearClick);
			});
		},
		
		"markCurrentValue": function () {
			$this.parent().find(".selectize-dropdown-content .option")
				.removeClass("current")
				.filter("[data-value=\"" + $this.val() + "\"]")
				.addClass("current");
		}
	};
	
	// Initialize select2 fields
	$this.selectize({
		"placeholder": config.placeholder
	});
	
	// Modify the dom
	setTimeout(methods.delayedInit, 200);
	
	// Register change event
	$this.on("change", methods.eventhandler.onValueChange);
});