/*
 * Copyright 2019 LABOR.digital
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Last modified: 2018.06.13 at 16:56
 */
import "./Autocomplete.sass";
import "devbridge-autocomplete/dist/jquery.autocomplete.js";
import {G8Framework} from "@labor/blocks.js/lib/Legacy";

G8Framework.initializeBlock("data-block-autocomplete", function ($this) {
	"use strict";
	
	// Instantiate autocomplete
	$this.autocomplete({
		"lookup": $this.dataFallback("block-autocomplete")
	});
});