/*
 * Copyright 2019 LABOR.digital
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Last modified: 2019.05.13 at 07:57
 */
import "./App.sass";
import 'jquery';
import "./Components/Form/Form.sass";
import "./Components/Login/Login.sass";
import "./Components/MainMenu/MainMenu.sass";
import "./Components/Page/Page.sass";
import "./Components/PageHeader/PageHeader.sass";
import "./Components/Pagination/Pagination.sass";
import "./Components/RecordDetail/RecordDetail.sass";
import "./Components/RecordList/RecordList.sass";
import "./Components/SideMenu/SideMenu.sass";
import "./Components/SqlDump/SqlDump.sass";
import "./Components/Breadcrumbs/Breadcrumbs.sass";
import "./Components/Dashboard/Dashboard.sass";
import "./Components/Error/Error.sass";
import "./Components/Flash/Flash.sass";
import "./Components/LeadValue/LeadValue.sass";
import "./Components/Organigram/Organigram.sass";
import "./Components/BlurText/BlurText.sass";
import "./Components/Help/Help.sass";
import "./Components/LeadHistory/LeadHistory.sass";
import "./Components/ListFilter/ListFilter.sass";
import "./Components/LeadImport/LeadImport.sass";
import "./Components/Dropdown/Dropdown";
import "./Components/SectionToggle/SectionToggle";
import "./Components/SelectableText/SelectableText";
import "./Components/Selectize/Selectize";
import "./Components/DateSelector/DateSelector";
import "./Components/Autocomplete/Autocomplete";
import "./Components/MoneyField/MoneyField";
import "./Components/LeadSnippetSelector/LeadSnippetSelector";
import {BlocksJs} from "@labor/blocks.js";


BlocksJs.registerInitialDomChange();