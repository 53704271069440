/*
 * Copyright 2019 LABOR.digital
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Last modified: 2018.01.12 at 17:32
 */

/**
 * Created by Martin Neundorfer on 14.09.2017.
 * For LABOR.digital
 */
import "./DateSelector.sass";
import "@fengyuanchen/datepicker";
import "@fengyuanchen/datepicker/i18n/datepicker.de-DE";
import {G8Framework} from "@labor/blocks.js/lib/Legacy";
import {PlainObject} from "@labor/helferlein/lib/Interfaces/PlainObject";

G8Framework.initializeBlock("data-block-date-selector", function ($this) {
	"use strict";
	
	var $obj: PlainObject = {
		"input": "data-bds-input",
		"canvas": "data-bds-canvas"
	};
	$this.obj($obj);
	
	var config = {
		// Values are day|month
		"mode": $this.dataFallback("bds-mode", "day"),
		"startDate": $this.dataFallback("bds-start-date", null),
		"endDate": $this.dataFallback("bds-end-date", true)
	};
	
	var methods = {
		"eventhandler": {
			"onInputClick": function (e) {
				methods.eventhandler.preventClick(e);
				methods.showDatepicker();
			},
			
			"preventClick": function (e) {
				e.preventDefault();
				e.stopPropagation();
				return false;
			},
			
			"onClickOut": function (e) {
				methods.hideDatepicker();
			},
			
			"onInputFieldChange": function (e) {
				var $o = $(this);
				var val = methods.parseHumanToDate($o.val());
				$obj.input.val(val);
				$obj.canvas.datepicker("setDate", val);
			},
			
			"onDatepickerPick": function (e) {
				// Check for month handling
				var isMonth = config.mode === "month" && e.view === "month";
				var isDay = e.view === "day";
				
				// Check if we have to skip here
				var skip = !isDay && !isMonth;
				if (skip) return;
				
				$obj.input.val($obj.canvas.datepicker("formatDate", e.date));
				methods.hideDatepicker();
			}
		},
		
		"showDatepicker": function () {
			$obj.canvas.css("padding-left", 0);
			$obj.canvas.slideDown(200, function () {
				$obj.canvas.css("padding-left", "");
			});
		},
		
		"hideDatepicker": function () {
			$obj.canvas.css("padding-left", 0);
			$obj.canvas.slideUp();
		},
		
		"initializeValue": function () {
			$obj.input.val(methods.parseHumanToDate($obj.input.val()));
		},
		
		"initializeDatepicker": function () {
			$obj.canvas.html("").datepicker({
				"inline": true,
				"weekStart": 1,
				"startView": 2,
				"language": "de-DE",
				"startDate": config.startDate,
				"endDate": config.endDate,
				"format": config.mode === "day" ? "dd.mm.yyyy" : "mm.yyyy",
				"pick": methods.eventhandler.onDatepickerPick
			});
			$obj.canvas.hide();
		},
		
		"parseHumanToDate": function (val) {
			// Ignore on empty
			if (typeof val === "string" && val.trim().length === 0) {
				return "";
			}
			
			// Try to unify date
			val = val.replace(/\s+/g, "").replace(/[\,\-\/\\\.]/g, ".").replace(/[^0-9\.]/g, "");
			var parts = val.split(".");
			
			var hasDay = config.mode === "month";
			var hasMonth = false;
			var hasYear = false;
			var day: any = 1;
			var month: any = 1;
			var year = 1960;
			
			// Handle continoous date
			if (parts.length === 1) {
				var length = (parts[0] + "").length;
				if (length === 6) {
					parts = parts[0].match(/.{1,2}/g);
				} else if (length === 3) {
					parts = parts[0].match(/.{1,1}/g);
				} else if (length === 4) {
					parts = parts[0].match(/.{1,1}/g);
					parts[2] = parts[2] + parts[3] + "";
					delete parts[3];
				}
			}
			
			for (var i = 0; i < parts.length; i++) {
				var part = parseInt(parts[i]);
				if (part === 0 || part < 0 || isNaN(part)) continue;
				// Handle correctly formatted date
				if (part > 1900) {
					year = part;
					hasYear = true;
					continue;
				}
				if (!hasDay && part <= 31) {
					day = part;
					hasDay = true;
					continue;
				}
				if (!hasMonth && part <= 12) {
					month = part;
					hasMonth = true;
					continue;
				}
				if (!hasMonth && part > 12 && hasDay && day <= 12 && part <= 31) {
					month = day;
					day = part;
					hasMonth = true;
					continue;
				}
				if (!hasYear && hasDay && part || i + 1 === parts.length) {
					if (part < 100) {
						year = 1900 + part;
					} else {
						year = part;
					}
					hasYear = true;
				}
			}
			
			// Validate date
			if (typeof $obj.canvas.data("datepicker") !== "undefined") {
				var startDate = $obj.canvas.data("datepicker").startDate;
				var endDate = $obj.canvas.data("datepicker").endDate;
				
				// Day
				if (startDate instanceof Date && day < startDate.getDate()) {
					day = startDate.getDate();
				}
				if (endDate instanceof Date && day > endDate.getDate()) {
					day = endDate.getDate();
				}
				// Month
				if (startDate instanceof Date && month < startDate.getMonth() + 1) {
					month = startDate.getMonth() + 1;
				}
				if (endDate instanceof Date && month > endDate.getMonth() + 1) {
					month = endDate.getMonth() + 1;
				}
				// Year
				if (startDate instanceof Date && year < startDate.getFullYear()) {
					year = startDate.getFullYear();
				}
				if (endDate instanceof Date && year > endDate.getFullYear()) {
					year = endDate.getFullYear();
				}
			}
			
			// Build date parts
			day = day + "";
			if (day.length < 2) day = "0" + day;
			month = month + "";
			if (month.length < 2) month = "0" + month;
			
			// Done
			if (config.mode === "month") {
				return month + "." + year;
			}
			return day + "." + month + "." + year;
		}
	};
	
	// Register eventhandler
	$obj.input
		.on("click focus", methods.eventhandler.onInputClick)
		.on("change", methods.eventhandler.onInputFieldChange);
	$obj.canvas.on("click", methods.eventhandler.preventClick);
	$(document).on("click", methods.eventhandler.onClickOut);
	
	// Initialize
	methods.initializeValue();
	methods.initializeDatepicker();
});