/*
 * Copyright 2019 LABOR.digital
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Last modified: 2018.01.12 at 12:12
 */

import {G8Framework} from "@labor/blocks.js/lib/Legacy";
import {PlainObject} from "@labor/helferlein/lib/Interfaces/PlainObject";

G8Framework.initializeBlock("data-block-dropdown", function ($this) {
	"use strict";
	
	var $obj: PlainObject = {
		"menu": "data-bd-menu"
	};
	$this.obj($obj);
	
	var vars = {
		open: false,
		ignoreNextGlobalClose: false
	};
	
	var config = {
		"allowSecondClick": $this.dataFallback("allow-second-click", false)
	};
	
	var methods = {
		"eventhandler": {
			"onClickDropdown": function (e) {
				if (vars.open) {
					if (!config.allowSecondClick) {
						e.preventDefault();
						e.stopPropagation();
						methods.close();
					}
				} else {
					methods.open();
					e.preventDefault();
					e.stopPropagation();
				}
			},
			
			"preventEvent": function (e) {
				if (vars.open) {
					e.stopPropagation();
				}
			},
			
			"onDocumentClick": function () {
				methods.close();
			},
			
			onGlobalClose: function () {
				if (vars.ignoreNextGlobalClose) {
					vars.ignoreNextGlobalClose = false;
					return;
				}
				methods.close();
			}
		},
		
		"open": function () {
			vars.open = true;
			$this.addClass("open");
			$obj.menu.addClass("open").removeClass("off");
			vars.ignoreNextGlobalClose = true;
			$(document).on("click", methods.eventhandler.onDocumentClick).trigger("dropdown__closeAll");
			$this.attr("aria-expanded", true);
		},
		
		"close": function () {
			vars.open = false;
			$this.removeClass("open");
			$obj.menu.addClass("off").removeClass("open");
			$(document).off("click", methods.eventhandler.onDocumentClick);
			$this.attr("aria-expanded", false);
		}
	};
	
	// Register eventhandler
	$this.on("click", methods.eventhandler.onClickDropdown);
	$this.on("openDropdown", methods.open);
	$this.on("closeDropdown", methods.close);
	$obj.menu.on("click", methods.eventhandler.preventEvent);
	$(document).on("dropdown__closeAll", methods.eventhandler.onGlobalClose);
});